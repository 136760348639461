import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV9 from './components/home-v9';
import About from './components/about';
import Service from './components/service';
import ServiceDetails from './components/service-details';
import Portfolio from './components/portfolio';
import PortfolioV2 from './components/portfolio-v2';
import PortfolioDetails from './components/portfolio-details';
import Team from './components/team';
import TeamDetails from './components/team-details';
import Faq from './components/faq';
import ComingSoon from './components/coming-soon';
import Error from './components/404';
import Location from './components/location';
import ProductDetails from './components/product-details'; // Fixed typo here
import BlogGrid from './components/blog-grid';
import BlogLeftSidebar from './components/blog-left-sidebar';
import BlogRightSidebar from './components/blog-right-sidebar';
import Blog from './components/blog';
import BlogDetails from './components/blog-details';
import Contact from './components/contact';

class Root extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={HomeV9} />
                    <Route path="/about" component={About} />
                    <Route path="/service" component={Service} />
                    <Route path="/service-details" component={ServiceDetails} />
                    <Route path="/portfolio" component={Portfolio} />
                    <Route path="/portfolio-v2" component={PortfolioV2} />
                    <Route path="/portfolio-details" component={PortfolioDetails} />
                    <Route path="/team" component={Team} />
                    <Route path="/team-details" component={TeamDetails} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/coming-soon" component={ComingSoon} />
                    <Route path="/404" component={Error} />
                    <Route path="/location" component={Location} />
                    <Route path="/product-details" component={ProductDetails} />
                    <Route path="/blog-grid" component={BlogGrid} />
                    <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
                    <Route path="/blog-right-sidebar" component={BlogRightSidebar} />
                    <Route path="/blog" component={Blog} />
                    <Route path="/blog-details" component={BlogDetails} />
                    <Route path="/contact" component={Contact} />
                </Switch>
            </div>
        );
    }
}

export default Root;

ReactDOM.render(
    <Router>
        <Root />
    </Router>,
    document.getElementById('Bramhaputra')
);
