import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CategoryV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-70">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Our Banking Partner</h6>
			          {/* <h1 className="section-title">Building Aminities</h1> */}
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center go-top">
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
				  <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center">
            <Link to="#">
			<img src={publicUrl+"assets/img/bank/1.png"} alt="SBI" />
            </Link> 
        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/2.png"} alt="PNB" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/3.png"} alt="Axis" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/4.png"} alt="Bandhan" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/5.png"} alt="BOB" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
                <img src={publicUrl+"assets/img/bank/6.png"} alt="BOI" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/7.png"} alt="HDFC" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/8.png"} alt="BRIB" />
            </Link> 
			        </div>
			      </div>
				  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/9.png"} alt="IIFL" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/10.png"} alt="LIC" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/11.png"} alt="Union" />
            </Link> 
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
					<Link to="#">
					<img src={publicUrl+"assets/img/bank/12.png"} alt="ICICI" />
            </Link> 
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default CategoryV2